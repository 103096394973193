import { isoly } from "isoly"
import { userwidgets } from "@userwidgets/model"
import { weekmeter } from "@weekmeter/model"
import { typedly } from "typedly"
import { stately } from "../../../../../../stately"
import { Client } from "../../../../../Client"
import type { State } from "../../../../index"
import { Entry as DayEntry } from "./Entry"

export type Activities = Partial<Record<weekmeter.Day.Activity, Activities.Entry>>
export namespace Activities {
	export import Entry = DayEntry
	export type Listenable = stately.Record<Activities>

	class Storage {
		constructor(
			private readonly user: userwidgets.Email,
			private readonly organization: userwidgets.Organization.Identifier,
			private readonly date: isoly.Date
		) {}
		private key(activity: weekmeter.Day.Activity): string {
			return `${this.user}|${this.organization}|${this.date}|${activity}`
		}
		load(activity: weekmeter.Day.Activity): Pick<Activities.Entry, "start" | "stop" | "break"> | undefined {
			const json = window.localStorage.getItem(this.key(activity))
			return !json ? undefined : JSON.parse(json)
		}
		store(activity: weekmeter.Day.Activity, entry: Pick<Activities.Entry, "start" | "stop" | "break">): void {
			if (
				!isoly.TimeSpan.toMilliseconds(entry.start ?? {}) &&
				!isoly.TimeSpan.toMilliseconds(entry.stop ?? {}) &&
				!isoly.TimeSpan.toMilliseconds(entry.stop ?? {})
			)
				this.remove(activity)
			else {
				window.localStorage.setItem(
					this.key(activity),
					JSON.stringify({ start: entry.start, stop: entry.stop, break: entry.break })
				)
			}
		}
		remove(activity: weekmeter.Day.Activity): void {
			window.localStorage.removeItem(this.key(activity))
		}
	}
	function load(storage: Storage, activity: weekmeter.Day.Activity, value: weekmeter.Time | undefined): Entry {
		if (value?.locked)
			storage.remove(activity)
		return { ...storage.load(activity), ...(Entry.from(value) ?? { duration: {} }) }
	}
	export function create(
		factory: State.Factory,
		user: userwidgets.Email,
		organization: userwidgets.Organization.Identifier,
		date: isoly.Date,
		day: weekmeter.Day,
		client: Client
	): Listenable {
		const storage = new Storage(user, organization, date)
		const me = factory.create<Activities>(
			"record",
			{
				initiate: ({ property }) => {
					const value = day[property]
					const result = load(storage, property, value)
					return result
				},
				store: async ({ state, property, current, value }) => {
					const activity = weekmeter.Day.Activity.parse(property)
					const response =
						current === undefined ||
						!activity ||
						!value ||
						!state?.user.me.key ||
						!state.user.organizations.current ||
						isoly.TimeSpan.toMilliseconds(current.duration) == isoly.TimeSpan.toMilliseconds(value.duration)
							? undefined
							: state.errors.handle(
									await client.times.update({
										...activity,
										value: value.duration,
										email: state.user.me.key.email,
										organization: state.user.organizations.current.id,
										date,
									})
							  ) || undefined
					if (response && state?.user.me.key)
						factory.reload(state.salaries.preview.reports, state.user.me.key.email)
					if (value)
						storage.store(property, value)
					return current === undefined ? value : !response ? value : { ...value, ...Activities.Entry.from(response) }
				},
			},
			typedly.Object.entries(day).reduce<Partial<Record<weekmeter.Day.Activity, Activities.Entry>>>(
				(result, [key, value]) => ({ ...result, [key]: load(storage, key, value) }),
				{}
			)
		)
		return me
	}
}
