export const sv = {
	"User view": "Visa användare",
	"User admin": "Administrera användare",
	"User invite": "Bjuda in användare",
	"Org view": "Visa org",
	"Org edit": "Redigera org",
	"Org create": "Skapa org",
	"App view": "Visa app",
	"App edit": "Redigera app",
	"App create": "Skapa app",
}
