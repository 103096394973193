import { typedly } from "typedly"
import { Dependencies } from "./Dependencies"
import { Listener } from "./Listener"

export class Listeners<L extends typedly.Object<L>> {
	readonly dependencies = Dependencies.create<L>()
	#events = new Set<keyof L>()
	get events(): Omit<Set<keyof L>, "add" | "clear" | "delete"> {
		return this.#events
	}
	private readonly backend: {
		[E in keyof L | "*"]?: Listener<L[keyof L], keyof L>[]
	} = {}
	private constructor() {}
	call<E extends keyof L>(event: keyof L, value: L[E]): void {
		this.backend[event]?.forEach(listener => listener(value, event))
		this.backend["*"]?.forEach(listener => listener(value, event))
		this.dependencies.activate(event)
	}
	add(event: keyof L | "*", listener: Listener<L[keyof L], keyof L>): Listener.Controller {
		if (!this.backend[event]?.push(listener)) {
			this.backend[event] = [listener]
			if (event !== "*")
				this.#events.add(event)
		}
		return {
			abort: () => {
				const index = this.backend[event]?.findIndex(l => l == listener)
				if (index && index !== -1) {
					this.backend[event]?.splice(index, 1)
				}
			},
		}
	}
	static create<L>(): Listeners<L> {
		return new this<L>()
	}
}
