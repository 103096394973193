import { Async } from "../../Async"
import { Listenable } from "../../Listenable"
import { Record as StatelyRecord } from "../../Record"
import type { Factory } from "../index"
import { navigation } from "../navigation"
import { Handler as Base } from "./Base"

export class Record<T> extends Base<StatelyRecord<T>> {
	private backend: () => T
	private listeners: Listenable.Listeners<StatelyRecord.ListenableParameters<T>>
	private configuration: Record.Configuration<StatelyRecord.Configuration<any>>
	constructor(factory: Factory, configuration: StatelyRecord.Configuration<T>, backend: T) {
		const processed = Record.processConfiguration(configuration)
		const { result, ...internals } = StatelyRecord.create(processed, backend, factory)
		super(factory, result)
		this.listeners = internals.listeners
		this.backend = internals.backend
		this.configuration = processed as any
	}
	start(target?: Listenable<any>): void {
		const configuration = this.configuration
		const backend = this.backend()
		if (backend == undefined)
			return
		for (const path in configuration?.invalidate ?? [])
			if (typeof path == "string") {
				const dependency = navigation.resolve(this.factory, backend, path)
				if (dependency && (!target || target === dependency.target)) {
					const memory = new Set<keyof T>()
					this.state.listen(
						"*",
						(_, event) => {
							if (!memory.has(event)) {
								memory.add(event)
								dependency.target.listen(dependency.key, () => this.reload(event as string), { passive: true })
							}
						},
						{ passive: true, trigger: "read" }
					)
				}
			}
		for (const path of configuration?.reload ?? [])
			if (typeof path == "string") {
				const dependency = navigation.resolve(this.factory, backend, path)
				if (dependency && (!target || target === dependency.target)) {
					const activated = new Set<keyof T>()
					this.state.listen(
						"*",
						(_, event) => {
							if (!activated.has(event)) {
								activated.add(event)
								dependency.target.listen(dependency.key, () => this.reload(event.toString()), { passive: true })
							}
						},
						{ passive: true, trigger: "read" }
					)
				}
			}
	}
	reload(event: string): void {
		if (event === "*")
			this.listeners.events.forEach(property => property !== "*" && this.reload(property as string))
		else {
			const current = this.backend()[event as keyof T]
			if (this.configuration) {
				if (current === undefined)
					this.state[event as keyof T] = this.configuration.initiate?.({
						state: this.factory.state,
						me: this.state,
						property: event,
						current,
					})
				this.configuration.load
					?.force({ state: this.factory.state, me: this.state, property: event, current })
					.then(result => (this.state[event as keyof T] = result))
			}
		}
	}
	private static processConfiguration<T>(
		configuration: StatelyRecord.Configuration<T>
	): Record.Configuration<StatelyRecord.Configuration<T>> {
		return {
			...(({ load, ...configuration }) => configuration)(configuration),
			...(configuration.load && {
				load: Async.lazy<typeof configuration.load>(
					() => Async.awaitLatest(async (...argument) => configuration.load?.(...argument)),
					argument => argument.property
				),
			}),
		}
	}
}
export namespace Record {
	export type Configuration<TConfiguration extends StatelyRecord.Configuration<any>> =
		TConfiguration extends StatelyRecord.Configuration<infer T>
			? {
					[Configuration in keyof StatelyRecord.Configuration<T>]?: Configuration extends "load"
						? Async.Lazy<Required<StatelyRecord.Configuration<T>>["load"]>
						: StatelyRecord.Configuration<T>[Configuration]
			  }
			: never
}
