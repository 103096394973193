import { gracely } from "gracely"
import { userwidgets } from "@userwidgets/model"
import { weekmeter } from "@weekmeter/model"
import { http } from "cloudly-http"

export class Reports {
	constructor(private readonly client: http.Client<gracely.Error>) {}
	async preview(
		organization: userwidgets.Organization.Identifier,
		report: weekmeter.Report.Project.Creatable
	): Promise<weekmeter.Report.Project | gracely.Error> {
		const search = new URLSearchParams()
		search.set("dry_run", "true")
		return await this.client.post(`/report/project?${search}`, report, { organization })
	}
}
