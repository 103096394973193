import { typedly } from "typedly"
import type { Factory } from "../Factory"
import { Listenable } from "../Listenable"
import type { Object } from "."
import { Configuration } from "./Configuration"

const promise = new Promise(() => {})
export type Properties<T, TState = unknown> = {
	[TProperty in keyof T]?: Configuration<T, TProperty, TState>
} & {
	"*"?: Configuration<T, keyof T, TState>
}
export namespace Properties {
	export function createHandler<T extends { [TKey in keyof typedly.Object]: typedly.Object[TKey] | false | undefined }>(
		properties: Properties<T>,
		listeners: Listenable.Listeners<T>,
		proxy: () => Object<T>,
		factory?: Factory<any>
	): ProxyHandler<T> {
		return {
			set(me: T, property: keyof T, value: any, state: Object<T>): boolean {
				const configuration = properties[property] ?? properties["*"]
				listeners.dependencies.activate(property)
				if (!configuration?.readonly && me[property] !== value) {
					;(configuration?.store
						? configuration.store({ state: factory?.state, me: state, property, current: me[property], value })
						: Promise.resolve(value)
					).then(value => {
						if (me[property] !== value) {
							me[property] = value
							listeners.call(property, value)
						}
					})
				}
				return !configuration?.readonly
			},
			get(me: T, property: keyof T, state: Object<T>): unknown | false | undefined {
				let result: unknown | false | undefined
				if (factory && !factory.ready)
					result = me[property]
				else if (!(property in me) && property in promise)
					result = undefined
				else {
					listeners.dependencies.activate(property)
					const configuration = properties[property] ?? properties["*"]
					const current = me[property]
					result = current
					if (current === undefined) {
						result = configuration?.initiate?.({ state: factory?.state, me: state, property, current }) as any
						if (result !== me[property])
							state[property] = result as any
					}
					if (current === false || current === undefined) {
						configuration?.load?.({ state: factory?.state, me: state, property, current }).then(result => {
							if (result !== me[property]) {
								state[property] = result as any
							}
						})
					}
				}
				return result
			},
			deleteProperty(_, property: keyof T) {
				proxy()[property] = undefined as any
				return true
			},
		}
	}
}
