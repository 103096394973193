import { gracely } from "gracely"
import { userwidgets } from "@userwidgets/model"
import { isly } from "isly"
import { stately } from "../../../stately"
import type { State } from "./index"

namespace Response {
	export const update = isly.object<{ organization: userwidgets.Organization }>({
		organization: userwidgets.Organization.type,
	})
	export const fetch = isly.array(userwidgets.Organization.type)
}

export interface Organizations {
	current?: stately.Loadable<userwidgets.Organization>
	value?: stately.Loadable<userwidgets.Organization[]>
	update(
		organization: userwidgets.Organization.Changeable,
		options?: { id?: userwidgets.Organization.Identifier; email?: true }
	): Promise<userwidgets.Organization | false>
}
export namespace Organizations {
	const storage = "userwidgets.organization.current"
	export function create(factory: State.Factory, client: userwidgets.ClientCollection): stately.Object<Organizations> {
		const state = factory.create<Organizations>(
			"object",
			{
				current: {
					load: async ({ me }) => {
						const id = window.localStorage.getItem(storage)
						const value = me.value || []
						return (id && value.find(organization => organization.id == id)) || value.at(0)
					},
					reload: ["organizations.value"],
					store: async ({ value }) => {
						if (!value)
							window.localStorage.removeItem(storage)
						else
							window.localStorage.setItem(storage, value.id)
						return value
					},
				},
				value: {
					load: async ({ state }) => {
						const result = !state ? false : state.errors.handle(await client.organization.list())
						return result
					},
					reload: ["me.key"],
				},
			},
			{
				update: async (organization, options): Promise<userwidgets.Organization | false> => {
					const id = options?.id ?? (state.current || undefined)?.id
					const result =
						!factory.state?.me.key || !id
							? false
							: factory.state.errors.handle(
									await client.organization
										.update(id, organization, options?.email && window.location.origin)
										.then(result =>
											Response.update.is(result)
												? result.organization
												: gracely.Error.is(result)
												? result
												: result.organization
										)
							  )
					if (result)
						factory.reload(state, "value")
					return result
				},
			}
		)
		return state
	}
}
