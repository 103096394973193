import { typedly } from "typedly"
import { Listener } from "./Listener"

export class Dependencies<T extends typedly.Object<T>> {
	private readonly added = new Map<keyof T | "*", Listener.Controller[]>()
	private readonly backend: {
		[E in keyof T | "*"]?: (() => Listener.Controller | void)[]
	} = {}
	protected constructor() {}
	add(event: keyof T | "*", callback: () => Listener.Controller | void): void {
		if (!this.backend[event]?.push(callback))
			this.backend[event] = [callback]
		// const added = this.added.get(event)
		// if (added) {
		// 	const result = callback()
		// 	if (result)
		// 		added.push(result)
		// }
	}
	remove(key: keyof T | "*"): void {
		this.added.get(key)?.forEach(controller => controller.abort())
		this.added.delete(key)
	}
	removeAll(): void {
		for (const key of this.added.keys())
			this.remove(key)
	}
	activate(event: keyof T | "*"): Listener.Controller[] {
		const result: Listener.Controller[] = []
		const backend = this.backend[event]
		const controllers = this.added.get(event)
		if (!controllers) {
			this.added.set(event, result)
			backend?.forEach(callback => {
				const controller = callback()
				if (controller)
					result.push(controller)
			})
			if (event !== "*")
				this.activate("*").forEach(callback => result.push(callback))
		}
		return result
	}
	static create<T extends typedly.Object<T>>(): Dependencies<T> {
		return new this<T>()
	}
}
