import { weekmeter } from "@weekmeter/model"
import { stately } from "../../../stately"
import { Client } from "../../Client"
import { State } from ".."

export type List = weekmeter.Project[]
export namespace List {
	export type Listenable = stately.List<List[number]>
	export function create(factory: State.Factory, client: Client): Listenable {
		const me = factory.create<List[number]>(
			"list",
			{
				load: async ({ state }) => {
					const result = !state?.user.organizations.current
						? undefined
						: state.errors.handle(await client.projects.list(state.user.organizations.current.id)) || undefined
					return result
				},
				reload: ["user.organizations.current"],
			},
			undefined
		)
		return me
	}
}
