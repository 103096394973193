import { stately } from "../../../../stately"
import { Client } from "../../../Client"
import { State } from "../.."
import { Preview as ReportsPreview } from "./Preview"

export interface Reports {
	readonly preview: Reports.Preview.Listenable
}
export namespace Reports {
	export import Preview = ReportsPreview
	export type Listenable = stately.Object<Reports>
	export function create(factory: State.Factory, client: Client): Listenable {
		const me = factory.create<Reports>(
			"object",
			{},
			{
				preview: Preview.create(factory, client),
			}
		)
		return me
	}
}
