import { weekmeter } from "@weekmeter/model"
import { stately } from "../../../../../stately"
import type { State } from "../../../index"

export type Reports = Partial<Record<weekmeter.Day.Project.Work, weekmeter.Report.Project>>
export namespace Reports {
	export type Listenable = stately.Record<Reports>
	export function create(factory: State.Factory): Listenable {
		const me = factory.create<Reports>(
			"record",
			{
				// load: async ({ state, property }) => {
				// 	const [client, project] = property.split("/")
				// 	return (await state?.projects.reports.preview.create({ client, project })) || undefined
				// },
			},
			{}
		)
		return me
	}
}
