import { userwidgets } from "@userwidgets/model"
import { weekmeter } from "@weekmeter/model"
import { typedly } from "typedly"
import { stately } from "../../stately"
import { Client } from "../Client"
import type { State } from "./index"

export interface Rules {
	editable?: weekmeter.Time.Rules
	common?: weekmeter.Time.Rule.Base[]
	user?: Partial<Record<userwidgets.Email, weekmeter.Time.Rule.Base[]>>
	readonly update: (rules: weekmeter.Time.Rules.Changeable) => Promise<weekmeter.Time.Rules | false>
}
export namespace Rules {
	export type Listenable = stately.Object<Rules>
	export function create(factory: State.Factory, client: Client): Listenable {
		const me = factory.create<Rules>(
			"object",
			{
				editable: {
					load: async ({ state, me }) => {
						let result: weekmeter.Time.Rules | undefined
						if (!state?.user.me.key || !state?.user.organizations.current || !state.user.users.value) {
							result = undefined
						} else {
							const key = state.user.me.key
							const organization = state.user.organizations.current
							const users = (
								!userwidgets.User.Permissions.check(key.permissions, organization.id, "rules.view")
									? [{ email: key.email }]
									: state.user.users.value.filter(user =>
											userwidgets.User.Permissions.organizations(user.permissions).includes(organization.id)
									  )
							).map(user => user.email)
							result =
								state.errors.handle(await client.rules.list(state.user.organizations.current.id, users)) || undefined
						}
						if (result && me.editable) {
							const users = me.editable.users
							result.users = Object.fromEntries(
								typedly.Object.entries(me.editable.users).filter(([email]) => !(email in users))
							)
						}
						return result
					},
					reload: ["user.users.value"],
				},
				common: {
					load: async ({ me }) => {
						return me.editable?.common.rules.reduce<weekmeter.Time.Rule.Base[]>(
							(result, rule) => result.concat(weekmeter.Time.Rule.parse(rule) ?? []),
							[]
						)
					},
					reload: ["rules.editable"],
				},
				user: {
					load: async ({ me }) => {
						return typedly.Object.entries(me.editable?.users).reduce<
							Partial<Record<userwidgets.Email, weekmeter.Time.Rule.Base[]>>
						>(
							(result, [user, { rules }]) => ({
								...result,
								[user]: rules?.reduce<weekmeter.Time.Rule.Base[]>(
									(result, rule) => result.concat(weekmeter.Time.Rule.parse(rule) ?? []),
									[]
								),
							}),
							{}
						)
					},
					reload: ["rules.editable"],
				},
			},
			{
				editable: undefined,
				common: undefined,
				user: undefined,
				update: async rules => {
					const result = !factory.state?.user.organizations.current
						? false
						: factory.state.errors.handle(await client.rules.update(factory.state.user.organizations.current.id, rules))
					if (result && me.editable)
						result.users = Object.entries(result.users).reduce(
							(result, [email, rules]) => ({ ...result, [email]: rules }),
							me.editable.users
						)
					me.editable = result || undefined
					return result
				},
			}
		)
		return me
	}
}
