import { weekmeter } from "@weekmeter/model"
import { stately } from "../../../stately"
import { Client } from "../../Client"
import type { State } from "../index"

export type List = weekmeter.User.Profile[]
export namespace List {
	export type Listenable = stately.List<List[number]>
	export function create(factory: State.Factory, client: Client): Listenable {
		const me = factory.create<List[number]>(
			"list",
			{
				load: async ({ state }) => {
					return !state?.user.organizations.current
						? undefined
						: state.errors.handle(await client.profiles.list(state.user.organizations.current.id)) || undefined
				},
				reload: ["user.organizations.current"],
			},
			undefined
		)
		return me
	}
}
