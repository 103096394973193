import { typedly } from "typedly"
import type { Factory } from "../Factory"
import { Listenable } from "../Listenable"
import { Configuration as ObjectConfiguration } from "./Configuration"
import { Properties as ObjectProperties } from "./Properties"

export type Object<T extends typedly.Object<T>> = T & Listenable<T>
// TODO: Using stately.Object as values on a stately.Object with a "*" listener causes
//       unwanted calls to the getter/setter which causes unwanted calls to initiate / load / save
//       Current solution os to check if the property in question exists in a dummy promise.
//       This means that any property names that exist in a promise are forbidden.
export namespace Object {
	export import Properties = ObjectProperties
	export import Configuration = ObjectConfiguration
	export function create<T extends typedly.Object<T>>(properties: Properties<T>, initial?: T): Object<T>
	export function create<T extends typedly.Object<T>>(
		properties: Properties<T>,
		initial: T | undefined,
		factory: Factory<any>
	): { object: Object<T>; backend: () => T; listeners: Listenable.Listeners<T> }
	export function create<T extends typedly.Object<T>>(
		properties: Properties<T>,
		initial?: T,
		factory?: Factory<any>
	): Object<T> | { object: Object<T>; backend: () => T; listeners: Listenable.Listeners<T> } {
		const backend = initial ?? ({} as T)
		const listeners = Listenable.Listeners.create<T>()
		const result: Object<T> = new Proxy<Object<T>>(
			Listenable.make(backend, listeners, property => result[property], factory),
			Properties.createHandler(properties, listeners, () => result, factory)
		)
		return !factory ? result : { object: result, backend: () => backend, listeners }
	}
	export function entries<T>(
		object: Object<T> | undefined
	): [Exclude<keyof T, keyof Listenable<T>>, T[Exclude<keyof T, keyof Listenable<T>>]][] {
		return object === undefined ? [] : (globalThis.Object.entries(object) as ReturnType<typeof entries>)
	}
	export function values<T>(object: Object<T> | undefined): T[Exclude<keyof T, keyof Listenable<T>>][] {
		return object === undefined ? [] : (globalThis.Object.values(object) as ReturnType<typeof values>)
	}
	export function keys<T>(object: Object<T> | undefined): Exclude<keyof T, keyof Listenable<T>>[] {
		return object === undefined ? [] : (globalThis.Object.keys(object) as ReturnType<typeof keys>)
	}
}
