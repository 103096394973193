import { typedly } from "typedly"
import type { Factory } from "../Factory"
import { Listenable } from "../Listenable"
import { Loadable } from "../Loadable"
import { Configuration as ListConfiguration } from "./Configuration"
import { Events as ListEvents } from "./Events"
import { Parameters as ListParameters } from "./Parameters"

export class List<T> extends typedly.Collection<T> implements typedly.Collection<T>, Listenable<List.Events<T>> {
	private controllers = new Map<(...argument: any[]) => any, Listenable.Listener.Controller>()
	private readonly listeners = Listenable.Listeners.create<List.Events<T>>()
	private load?: Promise<void> | undefined
	#backend: Loadable<readonly T[]>
	protected get backend(): readonly T[] {
		List.Events.values.forEach(event => this.listeners.dependencies.activate(event))
		const current = this.#backend
		let result = current
		if (current === undefined) {
			result = this.configuration?.initiate?.({ me: this, current: this.#backend })
			if (result !== this.#backend)
				this.from(result, "changed")
		}
		if (!this.load && (current === false || current === undefined)) {
			this.load = this.configuration.load?.({ me: this, current }).then(result => {
				this.load = undefined
				if (result !== this.#backend)
					this.from(result, "changed")
			})
		}
		return result || []
	}
	protected set backend(value: Loadable<readonly T[]>) {
		this.from(value, "changed")
	}
	get length(): number {
		return this.backend.length
	}
	protected constructor(
		private configuration: List.Configuration<T>,
		initial: readonly T[] | undefined,
		private readonly factory?: Factory<any>
	) {
		super()
		this.#backend = initial?.slice()
	}
	protected index(index: number): number {
		return index < 0 ? this.length + index : index
	}
	async from(value: Loadable<Iterable<T>>, event?: keyof List.Events<unknown>): Promise<Loadable<T[]>> {
		const result = (value = value === undefined || value === false || Array.isArray(value) ? value : [...value])
		return (
			this.configuration.store?.({
				state: this.factory?.state,
				me: this,
				event: event ?? "changed",
				current: this.#backend,
				value: result,
			}) ?? Promise.resolve(result)
		).then(result => {
			if (result !== this.#backend) {
				this.#backend = result
				const current = [...(this.#backend || [])]
				this.changed(current)
				if (!result) {
					this.changed("removed", current, 0)
					this.changed("replaced", [], current, 0)
				}
			}
			return result
		})
	}
	entries(): IterableIterator<[number, T]> {
		return this.backend.entries()
	}
	push(...item: readonly T[]): this {
		const current = this.toArray()
		current.push(...item)
		this.from(current, "added").then(result => result && this.changed("added", item, result.length - item.length))
		return this
	}
	peek(): T | undefined {
		return this.backend[0]
	}
	pop(): T | undefined {
		const current = this.backend
		const index = current.length - 1
		const result = current.at(index)
		if (result)
			this.from(this.backend.slice(-1), "removed").then(backend => backend && this.changed("removed", [result], index))
		return result
	}
	shift(): T | undefined {
		const current = this.backend
		const result = current.at(0)
		if (result)
			this.from(this.backend.slice(1), "removed").then(backend => backend && this.changed("removed", [result], 0))
		return result
	}
	replace(index: number, ...value: T[]): this {
		const current = this.backend
		const updated = [...current]
		const replaced = updated.splice(index, value.length, ...value)
		this.from(updated, "replaced").then(backend => backend && this.changed("replaced", value, replaced, index))
		return this
	}
	get(index: number): T | undefined {
		return this.backend[this.index(index)]
	}
	set(index: number, value: T): boolean {
		const current = this.backend
		const result = 0 <= index && index < current.length
		if (result)
			this.from([...current.slice(0, index), value, ...current.slice(index)], "replaced").then(
				backend => backend && this.changed("replaced", [value], [current[index]], index)
			)
		return result
	}
	invalidate(): void {
		this.from(undefined)
	}
	private changed(value: readonly T[]): void
	private changed(event: "added", value: readonly T[], index: number): void
	private changed(event: "removed", value: T[], index: number): void
	private changed(event: "replaced", value: T[], replaced: T[], index: number): void
	private changed(
		...argument:
			| [value: readonly T[]]
			| ["added", readonly T[], number]
			| ["removed", T[], number]
			| ["replaced", T[], T[], number]
	): void {
		if (typeof argument[0] != "string")
			this.listeners.call("changed", argument)
		else
			this.listeners.call(...(([event, ...parameters]) => [event, parameters] as const)(argument))
	}
	unlisten(listener: (...args: any[]) => any): void {
		this.controllers.get(listener)?.abort()
		this.controllers.delete(listener)
	}
	depend<E extends keyof ListEvents<T>>(event: E | "*", callback: () => Listenable.Listener.Controller | void): void {
		this.listeners.dependencies.add(event, callback)
	}
	listen<E extends keyof List.Events<T>>(
		// this "*" needs to go
		event: E | "*",
		listener: Listenable.Listener<List.Events<T>[E], E>
	): Listenable.Listener.Controller {
		const result = this.listeners.add(event as any, listener)
		if ((!this.factory || this.factory.ready) && event == "changed")
			(listener as Listenable.Listener<List.Events<T>["changed"], E>)([this.toArray()], event)
		this.controllers.set(listener, result)
		return result
	}
	static create<T>(configuration: List.Configuration<T>, initial?: readonly T[]): List<T>
	static create<T>(
		configuration: List.Configuration<T, any>,
		initial?: readonly T[],
		factory?: Factory<any>
	): { list: List<T>; backend: () => readonly T[] | undefined; listeners: Listenable.Listeners<List.Events<T>> }
	static create<T>(
		configuration: List.Configuration<T>,
		initial?: readonly T[],
		factory?: Factory<any>
	):
		| List<T>
		| { list: List<T>; backend: () => Loadable<readonly T[]>; listeners: Listenable.Listeners<List.Events<T>> } {
		const result = new List(configuration, initial, factory)
		return !factory ? result : { list: result, backend: () => result.#backend, listeners: result.listeners }
	}
	static is(value: unknown): value is List<unknown> {
		return value instanceof this
	}
}

export namespace List {
	export import Events = ListEvents
	export import Parameters = ListParameters
	export import Configuration = ListConfiguration
}
