import { userwidgets } from "@userwidgets/model"
import { stately } from "../../../stately"
import type { State } from "./index"

export interface Applications {
	current?: stately.Loadable<userwidgets.Application>
}
export namespace Applications {
	export function create(factory: State.Factory, client: userwidgets.ClientCollection): stately.Object<Applications> {
		const state = factory.create<Applications>(
			"object",
			{
				current: {
					load: async ({ state }) => {
						return !state ? false : state.errors.handle(await client.application.fetch())
					},
					reload: ["me.key"],
				},
			},
			{}
		)
		return state
	}
}
