import { userwidgets } from "@userwidgets/model"
import { stately } from "../../../../stately"
import type { State } from "../index"
import { Invite as MeInvite } from "./Invite"

export interface Me {
	readonly invite: stately.Object<Me.Invite>
	key?: stately.Loadable<userwidgets.User.Key>
	onUnauthorized?: () => Promise<boolean>
	login: (
		user: userwidgets.User.Credentials | userwidgets.User.Key,
		twoFactor?: string
	) => Promise<userwidgets.User.Key | userwidgets.User.Unauthenticated | false>
	register: (
		invite: userwidgets.User.Invite,
		credentials: userwidgets.User.Credentials.Register
	) => Promise<userwidgets.User.Key | false>
	join: (invite: userwidgets.User.Invite) => Promise<userwidgets.User.Key | false>
	logout: () => void
}
export namespace Me {
	export import Invite = MeInvite
	const storage = "userwidgets-token"
	const timers = new Set<number>()
	export function create(factory: State.Factory, client: userwidgets.ClientCollection): stately.Object<Me> {
		const token = window.sessionStorage.getItem(storage)
		if (token)
			userwidgets.User.Key.Verifier.create(client.configuration.publicKey)
				.unpack(token)
				.then(key => (state.key = key))
		const state = factory.create<Me>(
			"object",
			{
				key: {
					load: async ({ me, current }) => {
						if (!current)
							me.onUnauthorized?.()
						return current
					},
					store: async ({ me, value }) => {
						timers.forEach(timer => {
							window.clearTimeout(timer)
							timers.delete(timer)
						})
						if (value) {
							window.sessionStorage.setItem(storage, value.token)
							client.key = value.token
							timers.add(
								window.setTimeout(
									() => (me.key = undefined),
									new Date(value.expires).getTime() - new Date().getTime() - 10_000
								)
							)
						} else {
							window.sessionStorage.removeItem(storage)
							client.key = undefined
						}
						return value
					},
				},
				onUnauthorized: {
					store: async ({ current, value }) => {
						return current ? current : (client.onUnauthorized = value)
					},
				},
			},
			{
				invite: Invite.create(factory, client),
				login: async (
					user,
					twoFactor?: string
				): Promise<userwidgets.User.Key | userwidgets.User.Unauthenticated | false> => {
					let result: userwidgets.User.Key | userwidgets.User.Unauthenticated | false
					const attempted = !factory.state ? false : factory.state.errors.handle(await client.me.login(user, twoFactor))
					if (!factory.state)
						result /* = state.key */ = false
					if (userwidgets.User.Key.is(attempted))
						result = state.key = attempted
					else if (userwidgets.User.Unauthenticated.is(attempted)) {
						result = attempted
						// state.key = false
					} else
						result /* = state.key*/ = false
					return result
				},
				register: async (invite, credentials): Promise<userwidgets.User.Key | false> => {
					const result = !factory.state
						? false
						: factory.state.errors.handle(await client.me.register(invite, credentials))
					if (result)
						window.sessionStorage.setItem(storage, result.token)
					return (state.key = result)
				},
				join: async (invite): Promise<userwidgets.User.Key | false> => {
					const result = !factory.state ? false : factory.state.errors.handle(await client.me.join(invite))
					if (result)
						window.sessionStorage.setItem(storage, result.token)
					return (state.key = result)
				},
				logout: () => {
					state.key = undefined
					window.sessionStorage.removeItem(storage)
				},
			}
		)
		return state
	}
}
