import { gracely } from "gracely"
import { userwidgets } from "@userwidgets/model"
import { weekmeter } from "@weekmeter/model"
import { http } from "cloudly-http"

export class Profiles {
	constructor(private readonly client: http.Client<gracely.Error>) {}
	async update(
		organization: userwidgets.Organization.Identifier,
		email: userwidgets.Email,
		profile: weekmeter.User.Profile.Changeable
	): Promise<weekmeter.User.Profile | gracely.Error> {
		return this.client.patch(`/profile/${email}`, profile, { organization })
	}

	async fetch(
		organization: userwidgets.Organization.Identifier,
		user: userwidgets.Email
	): Promise<weekmeter.User.Profile | gracely.Error> {
		return this.client.get(`/profile/${user}`, { organization })
	}

	async list(organization: userwidgets.Organization.Identifier): Promise<weekmeter.User.Profile[] | gracely.Error> {
		return this.client.get(`/profile/`, { organization })
	}
}
