import { weekmeter } from "@weekmeter/model"
import { stately } from "../../../stately"
import type { State as State } from "../index"

export type Activities = weekmeter.Day.Activity[]
export namespace Activities {
	export type Listenable = stately.List<Activities[number]>
	export function create(factory: State.Factory): Listenable {
		const result = factory.create<Activities[number]>(
			"list",
			{
				load: async ({ state }) => {
					const result = state?.activities.list
						.map<weekmeter.Day.Activity>(result => `${result.client}/${result.project}/${result.code}`)
						.concat(["parental", "sick", "unpaid", "vacation"])
					return result
				},
				reload: ["activities.list.change", "times.days.*"],
			},
			undefined
		)
		return result
	}
}
