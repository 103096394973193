import { weekmeter } from "@weekmeter/model"
import { stately } from "../../../stately"
import { Client } from "../../Client"
import type { State } from "../index"
import { List as ClientsList } from "./List"

export interface Clients {
	list: Clients.List.Listenable
	readonly record?: Readonly<Partial<Record<weekmeter.Code, weekmeter.Client>>>
	create(client: weekmeter.Client.Creatable): Promise<weekmeter.Client | false>
}
export namespace Clients {
	export import List = ClientsList
	export type Listenable = stately.Object<Clients>
	export function create(factory: State.Factory, client: Client): Listenable {
		const me = factory.create<Clients>(
			"object",
			{
				record: {
					load: async ({ me }) => {
						return me.list.reduce<Partial<Record<weekmeter.Code, weekmeter.Client>>>(
							(result, client) => ({ ...result, [client.code]: client }),
							{}
						)
					},
					reload: ["clients.list.change"],
				},
			},
			{
				list: List.create(factory, client),
				record: undefined,
				create: async creatable => {
					const result: weekmeter.Client | false = !factory.state
						? false
						: factory.state.errors.handle(await client.clients.create(creatable))
					if (result)
						me.list.push(result)
					return result
				},
			}
		)
		return me
	}
}
