import { stately } from "../../../../stately"
import type { State } from ".."
import { Numbers as RecordNumbers } from "./Numbers"

export interface Record {
	readonly sum?: number
	readonly numbers: Record.Numbers.Listenable
}
export namespace Record {
	export import Numbers = RecordNumbers
	export type Listenable = stately.Object<Record>
	export function create(factory: State.Factory): Listenable {
		return factory.create<Record>(
			"object",
			{
				sum: {
					load: async ({ me }) => {
						const values = stately.Record.values(me.numbers).filter((value): value is number => value !== undefined)
						const result = values.reduce((result, number) => result + number, 0)
						console.log(`Record sum loaded: ${values.join(" + ") || 0} = ${result}`)
						return result
					},
					reload: ["record.numbers.*"],
				},
			},
			{
				numbers: Numbers.create(factory),
			}
		)
	}
}
