import { weekmeter } from "@weekmeter/model"
import { stately } from "../../../stately"
import { Client } from "../../Client"
import type { State as State } from "../index"
import { Activities as TimesActivities } from "./Activities"
import { Days as TimesDaysNew } from "./Days"

export interface Times {
	scope: stately.Loadable<weekmeter.Scope>
	readonly activities: Times.Activities.Listenable
	readonly days: Times.Days.Listenable
}
export namespace Times {
	export import Activities = TimesActivities
	export import Days = TimesDaysNew
	export type Listenable = stately.Object<Times>
	export function create(factory: State.Factory, client: Client): Listenable {
		const result = factory.create<Times>(
			"object",
			{
				scope: {
					initiate: (parameters: { me: Listenable }): weekmeter.Scope => {
						return (parameters.me.scope = weekmeter.Scope.now("week"))
					},
				},
			},
			{
				scope: weekmeter.Scope.now("week"),
				activities: Activities.create(factory),
				days: Days.create(factory, client),
			}
		)
		return result
	}
}
