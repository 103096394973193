import { isoly } from "isoly"
import { langly } from "langly"
import { userwidgets } from "@userwidgets/model"
import { stately } from "../../../../stately"
import type { State } from "../index"
import { Role as RolesRole } from "./Role"
import * as translation from "./translation"

export interface Roles {
	readonly translate?: langly.Translate | undefined
	readonly translator?: Roles.Translator
	readonly organization?: stately.Loadable<Roles.Role[]>
	value?: Roles.Role[] | undefined
	readonly default?: stately.Loadable<Roles.Role[]>
	readonly application?: stately.Loadable<Roles.Role[]>
}
export namespace Roles {
	export import Role = RolesRole
	export type Translator = (language: isoly.Language) => langly.Translate | undefined
	export function create(factory: State.Factory): stately.Object<Roles> {
		const state = factory.create<Roles>(
			"object",
			{
				translate: {
					load: async ({ me, state }) => {
						return state?.locales.language && me.translator?.(state.locales.language)
					},
					reload: ["locales.language", "roles.translator"],
					store: async ({ value }) => {
						return value
					},
				},
				translator: {
					initiate: () => {
						return translation.create
					},
					store: async ({ value }) => {
						return value ? value : translation.create
					},
				},
				value: {
					load: async ({ me }) => {
						return me.default || undefined
					},
					reload: ["roles.default"],
				},
				default: {
					load: async ({ me, state }) => {
						return admin(state?.me.key) ? me.application : me.organization
					},
					reload: ["roles.application", "roles.organization", "me.key"],
				},
				organization: {
					load: async ({ me, state }) => {
						return !state?.organizations.value || !me.translate
							? false
							: Role.from([
									...new Set((state.organizations.value || []).flatMap(organization => organization.permissions)),
							  ]).map(role => Role.translate(role, { custom: me.translate }))
					},
					reload: ["organizations.value", "roles.translate"],
				},
				application: {
					load: async ({ me, state }) => {
						return !state?.applications.current || !me.translate
							? false
							: Role.from(state.applications.current.permissions).map(role =>
									Role.translate(role, { custom: me.translate })
							  )
					},
					reload: ["applications.current", "roles.translate"],
				},
			},
			{}
		)
		return state
	}
	function admin(key: stately.Loadable<userwidgets.User.Key>): boolean {
		return !!(key && userwidgets.User.Permissions.check(key.permissions, "*", "user.edit"))
	}
}
