import { gracely } from "gracely"
import { userwidgets } from "@userwidgets/model"
import { weekmeter } from "@weekmeter/model"
import { http } from "cloudly-http"

export class Activities {
	constructor(private readonly client: http.Client<gracely.Error>) {}
	async create(activity: weekmeter.Activity.Creatable): Promise<weekmeter.Activity | gracely.Error> {
		return await this.client.post(`/activity`, activity, { organization: activity.organization })
	}
	async list(organization: userwidgets.Organization.Identifier): Promise<weekmeter.Activity[] | gracely.Error> {
		return await this.client.get(`/activity`, { organization })
	}
}
