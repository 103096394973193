import { Handler as Base } from "./Base"
import { List as HandlerList } from "./List"
import { Object as HandlerObject } from "./Object"
import { Record as HandlerRecord } from "./Record"

export type Handler = Base
export namespace Handler {
	export import List = HandlerList
	export import Object = HandlerObject
	export import Record = HandlerRecord
}
