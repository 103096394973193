import { stately } from "../../../stately"
import { List as StateList } from "./List"
import { Numbers as StateNumbers } from "./Numbers"
import { Object as StateObject } from "./Object"
import { Record as StateRecord } from "./Record"
export interface State {
	readonly sum?: number
	readonly list: State.List.Listenable
	readonly object: State.Object.Listenable
	readonly record: State.Record.Listenable
}
export namespace State {
	export type Listenable = stately.Object<State>
	export type Factory = stately.Factory<Listenable>
	export import Numbers = StateNumbers
	export import List = StateList
	export import Object = StateObject
	export import Record = StateRecord
	export function create(): Listenable {
		const factory = stately.Factory.create<Listenable>()
		const me = factory.create<State>(
			"object",
			{
				sum: {
					load: async ({ me }) => {
						const values = [me.list.sum, me.object.sum, me.record.sum].filter(
							(value): value is number => typeof value === "number"
						)
						const result = values.reduce((result, number) => result + number, 0)
						console.log(`State total loaded: ${values.join(" + ")} = ${result}`)
						return result
					},
					reload: ["list.sum", "object.sum", "record.sum"],
				},
			},
			{
				list: List.create(factory),
				object: Object.create(factory),
				record: Record.create(factory),
			}
		)
		return factory.start(me)
	}
}

const s3 = State.create()
Object.assign(globalThis, { s3 })
