import { userwidgets } from "@userwidgets/model"
import { weekmeter } from "@weekmeter/model"
import { stately } from "../../../../stately"
import { Client } from "../../../Client"
import type { State } from "../../index"

export type Reports = Partial<Record<userwidgets.Email, weekmeter.Report.Salary[]>>
export namespace Reports {
	export type Listenable = stately.Record<Reports>
	export function create(factory: State.Factory, client: Client): Listenable {
		const me = factory.create<Reports>(
			"record",
			{
				load: async ({ state, property }) => {
					const result = !state?.user.organizations.current
						? undefined
						: state.errors.handle(await client.salaries.list(state.user.organizations.current.id, property)) ||
						  undefined
					return result
				},
			},
			{}
		)
		return me
	}
}
