import { userwidgets } from "@userwidgets/model"
import { stately } from "../../../stately"
import { Applications as StateApplications } from "./Applications"
import { Locales as StateLocales } from "./Locales"
import { Me as StateMe } from "./Me"
import { Organizations as StateOrganizations } from "./Organizations"
import { Roles as StateRoles } from "./Roles"
import { Users as StateUsers } from "./Users"

export interface State {
	readonly errors: stately.Errors
	readonly locales: stately.Object<State.Locales>
	readonly applications: stately.Object<State.Applications>
	readonly me: stately.Object<State.Me>
	readonly organizations: stately.Object<State.Organizations>
	readonly roles: stately.Object<State.Roles>
	readonly users: stately.Object<State.Users>
}
export namespace State {
	export import Applications = StateApplications
	export import Locales = StateLocales
	export import Me = StateMe
	export import Organizations = StateOrganizations
	export import Roles = StateRoles
	export import Users = StateUsers
	export type Listenable = stately.Object<State>
	export type Factory = stately.Factory<Listenable>
	export function create(client: userwidgets.ClientCollection): Listenable {
		const factory = stately.Factory.create<State.Listenable>()
		const result = factory.create<State>(
			"object",
			{},
			{
				errors: stately.Errors.create(),
				applications: Applications.create(factory, client),
				locales: Locales.create(factory),
				me: Me.create(factory, client),
				organizations: Organizations.create(factory, client),
				roles: Roles.create(factory),
				users: Users.create(factory, client),
			}
		)
		return factory.start(result)
	}
}
