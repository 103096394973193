import { isoly } from "isoly"
import { stately } from "../../../stately"
import type { State } from "./index"

export interface Locales {
	current: isoly.Locale
	language: isoly.Language
}
export namespace Locales {
	const supported: [isoly.Locale, ...isoly.Locale[]] = ["en-GB", "sv-SE"]
	export function create(factory: State.Factory): stately.Object<Locales> {
		const locale = isoly.Locale.is(window.navigator.language) ? window.navigator.language : undefined
		const language = locale && isoly.Locale.toLanguage(locale)
		const current =
			supported.find(supported => supported == locale) ??
			(language && supported.find(supported => supported.startsWith(language))) ??
			supported[0]
		return factory.create<Locales>(
			"object",
			{
				current: {
					store: async ({ me, value }) => {
						const language = value && isoly.Locale.toLanguage(value)
						me.language = language ?? "en"
						return value && supported[0]
					},
				},
			},
			{
				current: current,
				language: isoly.Locale.toLanguage(current) ?? "en",
			}
		)
	}
}
