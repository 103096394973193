import { stately } from "../../../../stately"
import type { State } from ".."

export type Double = number[]
export namespace Double {
	export type Listenable = stately.List<Double[number]>
	export function create(factory: State.Factory): Listenable {
		const me = factory.create<Double[number]>(
			"list",
			{
				load: async ({ state }) => {
					console.log("List.Double load", state?.list.numbers.toArray())
					const result = state?.list.numbers.toArray().concat(state.list.numbers.toArray())
					console.log(`List Double loaded: [${result?.join(", ")}]`)
					return result
				},
				reload: ["list.numbers.change"],
			},
			undefined
		)
		return me
	}
}
