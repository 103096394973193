import { isoly } from "isoly"
import { weekmeter } from "@weekmeter/model"
import { isly } from "isly"

export interface Entry {
	readonly start?: isoly.TimeSpan
	readonly stop?: isoly.TimeSpan
	readonly break?: isoly.TimeSpan
	readonly duration: isoly.TimeSpan
	readonly locked?: boolean
}
export namespace Entry {
	const duration = isly.fromIs("isoly.TimeSpan", isoly.TimeSpan.is)
	export const type = isly.object<Entry>({
		start: duration.optional(),
		stop: duration.optional(),
		break: duration.optional(),
		duration: duration,
		locked: isly.boolean().optional(),
	})
	export const is = type.is
	export const flaw = type.flaw
	export function from(time: weekmeter.Time): Entry
	export function from(time: weekmeter.Time | undefined): Entry | undefined
	export function from(time: weekmeter.Time | undefined): Entry | undefined {
		return !time ? undefined : { duration: time.value, locked: !!time.locked }
	}
}
