import { stately } from "../../../../stately"
import type { State } from ".."

export type Numbers = Partial<Record<string, number>>
export namespace Numbers {
	export type Listenable = stately.Record<Numbers>
	export function create(factory: State.Factory): Listenable {
		const me = factory.create<Numbers>("record", {}, {})
		return me
	}
}
