import { gracely } from "gracely"
import { isoly } from "isoly"
import { userwidgets } from "@userwidgets/model"
import { weekmeter } from "@weekmeter/model"
import { http } from "cloudly-http"

export class Balances {
	constructor(private readonly client: http.Client<gracely.Error>) {}
	async fetch(
		organization: userwidgets.Organization.Identifier,
		email: userwidgets.Email,
		options?: { date?: isoly.Date }
	): Promise<weekmeter.Balance | gracely.Error> {
		const search = new URLSearchParams()
		if (options?.date)
			search.set("date", options.date)
		return await this.client.get(`/balance/${email}?${search}`, { organization })
	}
}
