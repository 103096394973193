import { gracely } from "gracely"
import { isoly } from "isoly"
import { userwidgets } from "@userwidgets/model"
import { weekmeter } from "@weekmeter/model"
import { http } from "cloudly-http"

export class Salaries {
	constructor(private readonly client: http.Client<gracely.Error>) {}
	async fetch(
		organization: userwidgets.Organization.Identifier,
		user: userwidgets.Email,
		date: isoly.Date
	): Promise<weekmeter.Report.Salary | gracely.Error> {
		return await this.client.get(`/report/salary/${user}/${date}`, { organization })
	}
	async latest(
		organization: userwidgets.Organization.Identifier,
		user: userwidgets.Email
	): Promise<weekmeter.Report.Salary | gracely.Error> {
		return await this.client.get(`/report/salary/${user}/latest`, { organization })
	}
	async list(
		organization: userwidgets.Organization.Identifier,
		user: userwidgets.Email
	): Promise<weekmeter.Report.Salary[] | gracely.Error> {
		return await this.client.get(`/report/salary/${user}`, { organization })
	}
	async create(
		organization: userwidgets.Organization.Identifier,
		report: weekmeter.Report.Salary.Creatable
	): Promise<weekmeter.Report.Salary | gracely.Error> {
		return await this.client.post(`/report/salary`, report, { organization })
	}
	async preview(
		organization: userwidgets.Organization.Identifier,
		report: weekmeter.Report.Salary.Creatable
	): Promise<weekmeter.Report.Salary | gracely.Error> {
		return await this.client.post(`/report/salary/preview`, report, { organization })
	}
	async remove(
		organization: userwidgets.Organization.Identifier,
		user: userwidgets.Email,
		date: isoly.Date
	): Promise<weekmeter.Report.Salary | gracely.Error> {
		return await this.client.delete(`/report/salary/${user}/${date}`, { organization })
	}
}
