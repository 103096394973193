import { userwidgets } from "@userwidgets/model"
import { weekmeter } from "@weekmeter/model"
import { stately } from "../../../../stately"
import { Client } from "../../../Client"
import type { State } from "../../index"

export type Reports = Partial<Record<userwidgets.Email, weekmeter.Report.Salary>>
export namespace Reports {
	export type Listenable = stately.Record<Reports>
	export function create(factory: State.Factory, _: Client): Listenable {
		const me = factory.create<Reports>(
			"record",
			{
				load: async ({ state, property }) => {
					return (await state?.salaries.preview.create(property)) || undefined
				},
				// invalidate: ["salaries.preview.date"],
				reload: ["salaries.preview.date"],
			},
			{}
		)
		return me
	}
}
