import { weekmeter } from "@weekmeter/model"
import { stately } from "../../../stately"
import { Client } from "../../Client"
import type { State } from "../index"
import { List as ActivitiesList } from "./List"
export interface Activities {
	readonly list: Activities.List.Listenable
	readonly record?: Readonly<Partial<Record<weekmeter.Day.Activity.Work, weekmeter.Activity>>>
	create(activity: weekmeter.Activity.Creatable): Promise<weekmeter.Activity | false>
}
export namespace Activities {
	export import List = ActivitiesList
	export type Listenable = stately.Object<Activities>
	export function create(factory: State.Factory, client: Client): Listenable {
		const me = factory.create<Activities>(
			"object",
			{
				record: {
					load: async ({ me }) => {
						return me.list.reduce<Partial<Record<weekmeter.Day.Activity.Work, weekmeter.Activity>>>(
							(result, activity) => ({
								...result,
								[`${activity.client}/${activity.project}/${activity.code}`]: activity,
							}),
							{}
						)
					},
					reload: ["activities.list.change"],
				},
			},
			{
				list: List.create(factory, client),
				record: undefined,
				create: async activity => {
					let result: weekmeter.Activity | false
					if (!factory.state)
						result = false
					else {
						result = factory.state.errors.handle(await client.activities.create(activity))
						if (result)
							me.list.push(result)
						factory.reload(factory.state.projects.list, "change")
					}
					return result
				},
			}
		)
		return me
	}
}
