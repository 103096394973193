import { userwidgets } from "@userwidgets/model"
import { weekmeter } from "@weekmeter/model"
import { stately } from "../../../stately"
import { Client } from "../../Client"
import type { State as State } from "../index"
import { List as ProfilesList } from "./List"

export interface Profiles {
	readonly list: ProfilesList.Listenable
	readonly record?: Partial<Record<userwidgets.Email, weekmeter.User.Profile>>
	update(user: userwidgets.Email, profile: weekmeter.User.Profile.Changeable): Promise<weekmeter.User.Profile | false>
}
export namespace Profiles {
	export import List = ProfilesList
	export type Listenable = stately.Object<Profiles>
	export function create(factory: State.Factory, client: Client): Listenable {
		const me = factory.create<Profiles>(
			"object",
			{
				record: {
					load: async ({ me }) => {
						return me.list.reduce<Partial<Record<userwidgets.Email, weekmeter.User.Profile>>>(
							(result, profile) => ({ ...result, [profile.email]: profile }),
							{}
						)
					},
					reload: ["profiles.list.change"],
				},
			},
			{
				list: List.create(factory, client),
				record: undefined,
				update: async (user, profile) => {
					const index = me.list.findIndex(profile => profile.email == user)
					const result = !factory.state?.user.organizations.current
						? false
						: factory.state.errors.handle(
								await client.profiles.update(factory.state.user.organizations.current.id, user, profile)
						  )
					if (result)
						if (index == -1)
							me.list.push(result)
						else
							me.list.replace(index, result)
					return result
				},
			}
		)
		return me
	}
}
