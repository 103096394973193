import { isoly } from "isoly"
import { userwidgets } from "@userwidgets/model"
import { weekmeter } from "@weekmeter/model"
import { stately } from "../../../../stately"
import { Client } from "../../../Client"
import type { State } from "../../index"
import { Reports as PreviewsReports } from "./Reports"

export interface Preview {
	date: isoly.Date
	readonly reports: Preview.Reports.Listenable
	create(user: userwidgets.Email): Promise<weekmeter.Report.Salary | false>
}
export namespace Preview {
	export import Reports = PreviewsReports
	export type Listenable = stately.Object<Preview>
	export function create(factory: State.Factory, client: Client): Listenable {
		const controllers: Partial<Record<userwidgets.Email, () => void>> = {}
		const me: Listenable = factory.create<Preview>(
			"object",
			{},
			{
				date: isoly.Date.lastOfMonth(isoly.Date.previousMonth(isoly.Date.now())),
				reports: Reports.create(factory, client),
				create: async user => {
					controllers[user]?.()
					const result =
						!factory.state?.user.organizations.current || !me.date
							? false
							: factory.state.errors.handle(
									await client.salaries.preview(factory.state.user.organizations.current.id, {
										type: "salary",
										email: user,
										date: me.date,
									})
							  )
					if (result) {
						me.reports[result.email] = result
						const controller = factory.state?.salaries.history.reports.listen(result.email, history => {
							const latest = history?.at(0)
							if (latest && latest.dates.end >= result.dates.end && me.date)
								me.create(user)
						})
						controllers[result.email] = () => {
							delete controllers[result.email]
							controller?.abort()
						}
					}
					return result
				},
			}
		)
		return me
	}
}
