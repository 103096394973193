import { stately } from "../../../../stately"
import type { State } from ".."
import { Numbers as ObjectNumbers } from "./Numbers"

export interface Object {
	readonly sum?: number
	readonly numbers: Object.Numbers.Listenable
}
export namespace Object {
	export import Numbers = ObjectNumbers
	export type Listenable = stately.Object<Object>
	export function create(factory: State.Factory): Listenable {
		const me = factory.create<Object>(
			"object",
			{
				sum: {
					load: async ({ me }) => {
						const values = [me.numbers.a, me.numbers.b, me.numbers.c].filter(
							(value): value is number => typeof value === "number"
						)
						const result = (me.numbers.a ?? 0) + (me.numbers.b ?? 0) + (me.numbers.c ?? 0)
						console.log(`Object sum loaded: ${values.join(" + ") || 0} = ${result}`)
						return result
					},
					reload: ["object.numbers.a", "object.numbers.b", "object.numbers.c"],
				},
			},
			{
				numbers: Numbers.create(factory),
			}
		)
		return me
	}
}
