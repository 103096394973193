import { gracely } from "gracely"
import { isoly } from "isoly"
import { userwidgets } from "@userwidgets/model"
import { weekmeter } from "@weekmeter/model"
import { http } from "cloudly-http"

export class Times {
	constructor(private readonly client: http.Client<gracely.Error>) {}
	async list(
		organization: userwidgets.Organization.Identifier,
		user: userwidgets.Email,
		week: number | isoly.Date,
		year: number | isoly.Date
	): Promise<weekmeter.Time[] | gracely.Error> {
		const search = new URLSearchParams()
		search.set("year", (typeof year == "number" ? year : isoly.Date.getYear(year)).toString(10))
		search.set("week", (typeof week == "number" ? week : isoly.Date.getWeek(week)).toString(10))
		search.set("email", user)
		const result = await this.client.get<weekmeter.Time[]>(`/time?${search.toString()}`, { organization })
		return result
	}
	async update(time: weekmeter.Time.Changeable): Promise<weekmeter.Time | gracely.Error> {
		return await this.client.patch(`/time`, time, { organization: time.organization })
	}
}
