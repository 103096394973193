import { isoly } from "isoly"
import { weekmeter } from "@weekmeter/model"
import { stately } from "../../../../../stately"
import { Client } from "../../../../Client"
import type { State } from "../../../index"
import { Reports as PreviewReports } from "./Reports"

export interface Preview {
	dates: isoly.DateRange
	readonly reports: Preview.Reports.Listenable
	create(
		report: Omit<weekmeter.Report.Project.Creatable, "dates" | "type"> &
			Pick<Partial<weekmeter.Report.Project.Creatable>, "dates">
	): Promise<weekmeter.Report.Project | false>
}
export namespace Preview {
	export import Reports = PreviewReports
	export type Listenable = stately.Object<Preview>
	export function create(factory: State.Factory, client: Client): Listenable {
		const start = isoly.Date.previousMonth(isoly.Date.firstOfMonth(isoly.Date.now()))
		const me = factory.create<Preview>(
			"object",
			{},
			{
				dates: { start, end: isoly.Date.lastOfMonth(start) },
				create: async (creatable): Promise<weekmeter.Report.Project | false> => {
					const result = !factory.state?.user.organizations.current
						? false
						: factory.state.errors.handle(
								await client.projects.reports.preview(factory.state.user.organizations.current.id, {
									type: "project",
									client: creatable.client,
									project: creatable.project,
									dates: creatable.dates ?? me.dates,
								})
						  )
					if (result)
						me.reports[`${result.client}/${result.project}`] = result
					return result
				},
				reports: Reports.create(factory),
			}
		)
		return me
	}
}
