import { isly } from "isly"
import { typedly } from "typedly"
import type { Factory } from "../Factory"
import { Listener as ListenableListener } from "./Listener"
import { Listeners as ListenableListeners } from "./Listeners"

// TODO must support unlisten to be compatible with userwidgets
export interface Listenable<L> {
	unlisten(listener: (...args: any[]) => any): void
	listen<E extends keyof L>(
		event: E | "*",
		listener: typeof event extends "*" ? Listenable.Listener<L[keyof L], keyof L> : Listenable.Listener<L[E], E>,
		options?: { lazy?: true }
	): Listenable.Listener.Controller
	// depend = on own property access
	depend<E extends keyof L>(event: E | "*", callback: () => Listenable.Listener.Controller | void): void
}

export namespace Listenable {
	export import Listener = ListenableListener
	export type Listeners<L extends typedly.Object<L>> = ListenableListeners<L>
	export const Listeners = ListenableListeners
	export const type = isly.object<Listenable<any>>({
		unlisten: isly.function(),
		listen: isly.function(),
		depend: isly.function(),
	})
	export const is = type.is
	export function make<T extends typedly.Object<T>, L extends typedly.Object<L> = T>(
		initial: T,
		listeners: Listenable.Listeners<L>,
		get: (event: keyof L) => L[keyof L],
		factory?: Factory<any>
	): T & Listenable<L> {
		const controllers = new Map<(...argument: any[]) => any, Listenable.Listener.Controller>()
		return Object.defineProperties(initial, {
			unlisten: {
				enumerable: false,
				configurable: true,
				writable: true,
				value: (listener: (...argument: any[]) => any) => {
					controllers.get(listener)?.abort()
					controllers.delete(listener)
				},
			},
			depend: {
				enumerable: false,
				configurable: true,
				writable: true,
				value: <E extends keyof L | "*">(event: E, callback: () => Listenable.Listener.Controller): void => {
					listeners.dependencies.add(event, callback)
				},
			},
			listen: {
				enumerable: false,
				configurable: true,
				writable: true,
				value: (
					event: keyof L | "*",
					listener: Listenable.Listener<L[keyof L], keyof L>,
					options?: { lazy?: true }
				): Listenable.Listener.Controller => {
					const result = listeners.add(event, listener)
					if ((!factory || factory.ready) && !options?.lazy)
						if (event !== "*")
							listener(get(event), event)
						else
							typedly.Object.keys(initial).forEach(event => listener(get(event), event as any))
					controllers.set(listener, result)
					return result
				},
			},
		}) as T & Listenable<T>
	}
}
