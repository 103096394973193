import { userwidgets } from "@userwidgets/model"
import { weekmeter } from "@weekmeter/model"
import { stately } from "../../../stately"
import { Client } from "../../Client"
import type { State } from "../index"
import { List as ProjectsList } from "./List"
import { Reports as ProjectReports } from "./Reports"

export interface Projects {
	readonly reports: Projects.Reports.Listenable
	readonly list: Projects.List.Listenable
	readonly record?: Partial<Record<weekmeter.Day.Project.Work, weekmeter.Project>>
	readonly engines?: Partial<Record<userwidgets.Email, weekmeter.Project.Access.Engine>>
	create(project: weekmeter.Project.Creatable): Promise<weekmeter.Project | false>
	update(project: weekmeter.Project.Changeable): Promise<weekmeter.Project | false>
}
export namespace Projects {
	export import Reports = ProjectReports
	export import List = ProjectsList
	export type Listenable = stately.Object<Projects>
	export function create(factory: State.Factory, client: Client): Listenable {
		const me = factory.create<Projects>(
			"object",
			{
				record: {
					load: async ({ me }) => {
						return me.list.reduce<Partial<Record<weekmeter.Day.Project.Work, weekmeter.Project>>>(
							(result, project) => ({ ...result, [`${project.client.code}/${project.code}`]: project }),
							{}
						)
					},
					reload: ["projects.list.change"],
				},
				engines: {
					load: async ({ state }) => {
						let result: Partial<Record<userwidgets.Email, weekmeter.Project.Access.Engine>>
						if (!state?.user.me.key)
							result = {}
						else {
							const email = state.user.me.key.email
							result = state.projects.list.reduce<typeof result>(
								(result, project) => ({
									...result,
									[`${project.client.code}/${project.code}`]: weekmeter.Project.Access.Engine.create(project, {
										email,
										profile: state.profiles.record?.[email],
									}),
								}),
								{}
							)
						}
						return result
					},
					reload: ["user.me.key", "projects.list.change", "profiles.record"],
				},
			},
			{
				reports: Reports.create(factory, client),
				list: List.create(factory, client),
				record: undefined,
				engines: undefined,
				create: async project => {
					const result = !factory.state ? false : factory.state.errors.handle(await client.projects.create(project))
					if (result)
						me.list.push(result)
					return result
				},
				update: async project => {
					let result: weekmeter.Project | false
					const index = me.list.findIndex(project => weekmeter.Project.key(project) == weekmeter.Project.key(project))
					if (index == -1)
						result = false
					else {
						result = !factory.state ? false : factory.state.errors.handle(await client.projects.update(project))
						if (result)
							me.list.replace(index, result)
					}
					return result
				},
			}
		)
		return me
	}
}
