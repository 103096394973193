import { gracely } from "gracely"
import { userwidgets } from "@userwidgets/model"
import { http } from "cloudly-http"
import { Activities } from "./Activities"
import { Balances } from "./Balances"
import { Clients } from "./Clients"
import { Profiles } from "./Profiles"
import { Projects } from "./Projects"
import { Rules } from "./Rules"
import { Salaries } from "./Salaries"
import { Times } from "./Times"

const url = new URL(window.location.href)
const token = window.sessionStorage.getItem("token") ?? undefined
const backend =
	url.searchParams.get("backend") ??
	(url.host.search(/:\d{1,5}$/) != -1
		? // port specified -> local. change port to 8787
		  `${url.protocol}//${url.hostname}:8789`
		: // port not specified -> prod. change subdomain to api
		  `${url.protocol}//api.weekmeter.com`)
// const backend = url.searchParams.get("backend") ?? "https://api.weekmeter.com"
export class Client {
	onUnauthorized?: (client: Client) => Promise<boolean>
	set url(value: string | undefined) {
		this.client.url = value
	}
	set key(value: string | undefined) {
		this.client.key = value
		this.client.authorization = value === undefined ? undefined : { bearer: value }
	}
	get key(): string | undefined {
		return this.client.key
	}
	private readonly client: http.Client<gracely.Error>
	constructor(client: http.Client<gracely.Error>) {
		// TODO: this is not an okay solution...
		this.client = new Proxy(client, {
			set: (backend: any, property: string, value: any): boolean => {
				backend[property] = value
				if (property === "key")
					backend["authorization"] = typeof value == "string" ? { bearer: value } : undefined
				return true
			},
		})
		this.user = new userwidgets.ClientCollection(this.client as any, {})
		this.clients = new Clients(this.client)
		this.projects = new Projects(this.client)
		this.activities = new Activities(this.client)
		this.times = new Times(this.client)
		this.balances = new Balances(this.client)
		this.rules = new Rules(this.client)
		this.salaries = new Salaries(this.client)
		this.profiles = new Profiles(this.client)
	}
	readonly user: userwidgets.ClientCollection
	readonly clients: Clients
	readonly projects: Projects
	readonly activities: Activities
	readonly times: Times
	readonly balances: Balances
	readonly rules: Rules
	readonly salaries: Salaries
	readonly profiles: Profiles
	static create(url: string, key?: string): Client {
		return new this(new http.Client<Error>(url, key))
	}
}

export const client = Client.create(backend, token)
