import { isoly } from "isoly"
import { userwidgets } from "@userwidgets/model"
import { weekmeter } from "@weekmeter/model"
import { typedly } from "typedly"
import { stately } from "../../../../stately"
import { Client } from "../../../Client"
import type { State } from "../../index"
import { Day as DaysDay } from "./Day"

export type Days = Partial<Record<isoly.Date, Days.Day.Listenable>>
export namespace Days {
	export import Day = DaysDay
	export type Listenable = stately.Record<Days>
	const dates = new Set<isoly.Date>()
	export function create(factory: State.Factory, client: Client): Listenable {
		const me = factory.create<Days>(
			"record",
			{
				load: async ({ me, property, state, current }) => {
					let result: stately.Loadable<Days>
					if (!state?.times.scope || dates.has(property) || !isoly.Date.is(property)) {
						result = false
					} else {
						result =
							!state.user.me.key || !state.user.organizations.current
								? undefined
								: await load(
										factory,
										me,
										state.user.me.key.email,
										state.user.organizations.current.id,
										weekmeter.Scope.Week.from(property),
										client
								  )
						if (result && property in result)
							dates.add(property)
					}
					if (!result)
						result = { [property]: current }
					Object.assign(me, (({ [property]: _, ...result }) => result)(result))
					return result[property]
				},
				reload: ["times.scope", "user.organizations.current"],
				store: async ({ value }) => {
					return value
				},
			},
			{}
		)
		return me
	}
	const load = stately.Async.lazy(
		() =>
			async (
				factory: State.Factory,
				days: Listenable,
				user: userwidgets.Email,
				organization: userwidgets.Organization.Identifier,
				week: weekmeter.Scope.Week,
				client: Client
			): Promise<Days | false> => {
				const result = typedly.Object.reduce<Days>(
					{
						...typedly.Object.from<Partial<Record<isoly.Date, weekmeter.Day>>>(
							weekmeter.Scope.Week.getDays(week).map<[isoly.Date, weekmeter.Day]>(date => [date, {}])
						),
						...weekmeter.Days.from(
							!factory.state?.user.me.key || !factory.state.user.organizations.current
								? false
								: factory.state.errors.handle(
										await client.times.list(
											factory.state.user.organizations.current.id,
											factory.state.user.me.key.email,
											weekmeter.Scope.Week.getWeek(week),
											weekmeter.Scope.Week.getYear(week)
										)
								  )
						),
					},
					(result, [date, day]) => {
						return !isoly.Date.is(date) || !day
							? result
							: { ...result, [date]: Day.create(factory, days, user, organization, date, day, client) }
					},
					{}
				)
				return result
			},
		(...argument) => argument[3]
	)
}
