import { userwidgets } from "@userwidgets/model"
import { stately } from "../../../../stately"
import type { State } from "../index"

export interface Invite {
	value?: string | false | undefined
}
export namespace Invite {
	export function create(factory: State.Factory, client: userwidgets.ClientCollection): stately.Object<Invite> {
		const result = factory.create<Invite>(
			"object",
			{
				value: {
					load: async ({ state }) => {
						const invite = new URL(window.location.href).searchParams.get(client.configuration.inviteParameterName)
						return !invite
							? false
							: invite.match(/^[^.]+\.[^.]+\.[^.]+$/)
							? invite
							: !state
							? false
							: state.errors.handle(await client.me.invite.fetch(invite))
					},
				},
			},
			{}
		)

		return result
	}
}
