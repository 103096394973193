import { stately } from "../../../stately"
import { Client } from "../../Client"
import type { State } from "../index"
import { History as SalariesHistory } from "./History"
import { Preview as SalariesPreview } from "./Preview"

export interface Salaries {
	readonly history: Salaries.History.Listenable
	readonly preview: Salaries.Preview.Listenable
}
export namespace Salaries {
	export import History = SalariesHistory
	export import Preview = SalariesPreview
	export type Listenable = stately.Object<Salaries>
	export function create(factory: State.Factory, client: Client): Listenable {
		const me = factory.create<Salaries>(
			"object",
			{},
			{
				history: History.create(factory, client),
				preview: Preview.create(factory, client),
			}
		)
		return me
	}
}
