import { stately } from "../../../../stately"
import type { State } from ".."

export type Numbers = number[]
export namespace Numbers {
	export type Listenable = stately.List<Numbers[number]>
	export function create(factory: State.Factory): Listenable {
		const me = factory.create<Numbers[number]>(
			"list",
			{
				initiate: ({ current }) => {
					console.log("List.Numbers initiated", current)
					return current
				},
				load: async ({ current }) => {
					console.log("List.Numbers loaded", current)
					return current
				},
				next: async ({ current }) => {
					console.log("List.Numbers next", current)
					return current
				},
				push: async ({ current, value }) => {
					console.log("List.Numbers pushed", current, value)
					return value
				},
				change: async ({ current, value }) => {
					console.log("List.Numbers changed", current, value)
					return value
				},
				replace: async ({ index, value }) => {
					console.log("List.Numbers replaced")
					return { index, value }
				},
				remove: async ({ index }) => {
					console.log("List.Numbers removed")
					return { index }
				},
			},
			undefined
		)
		return me
	}
}
