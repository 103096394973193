import { gracely } from "gracely"
import { userwidgets } from "@userwidgets/model"
import { weekmeter } from "@weekmeter/model"
import { http } from "cloudly-http"
import { Reports } from "./Reports"

export class Projects {
	readonly reports: Reports
	constructor(private readonly client: http.Client<gracely.Error>) {
		this.reports = new Reports(client)
	}
	async list(organization: userwidgets.Organization.Identifier): Promise<weekmeter.Project[] | gracely.Error> {
		return await this.client.get(`/project`, { organization })
	}
	async create(project: weekmeter.Project.Creatable): Promise<weekmeter.Project | gracely.Error> {
		return await this.client.post(`/project`, project, { organization: project.organization })
	}
	async update(project: weekmeter.Project.Changeable): Promise<weekmeter.Project | gracely.Error> {
		return await this.client.patch(`/project`, project, { organization: project.organization })
	}
}
