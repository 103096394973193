import { weekmeter } from "@weekmeter/model"
import { stately } from "../../../stately"
import { Client } from "../../Client"
import type { State } from "../index"

export type List = weekmeter.Activity[]
export namespace List {
	export type Listenable = stately.List<List[number]>
	export function create(factory: State.Factory, client: Client): Listenable {
		const me = factory.create<List[number]>(
			"list",
			{
				load: async ({ state }) => {
					const result = !state?.user.organizations.current
						? false
						: state.errors.handle(await client.activities.list(state.user.organizations.current.id))
					return result || undefined
				},
				reload: ["user.organizations.current"],
			},
			undefined
		)
		return me
	}
}
