import { isoly } from "isoly"
import { userwidgets } from "@userwidgets/model"
import { weekmeter } from "@weekmeter/model"
import { stately } from "../../../../stately"
import { Client } from "../../../Client"
import type { State } from "../../index"
import { Reports as HistoryReports } from "./Reports"

export interface History {
	readonly reports: History.Reports.Listenable
	create(report: Omit<weekmeter.Report.Salary.Creatable, "type">): Promise<weekmeter.Report.Salary | false>
	remove(user: userwidgets.Email, date: isoly.Date): Promise<weekmeter.Report.Salary | false>
}
export namespace History {
	export import Reports = HistoryReports
	export type Listenable = stately.Object<History>
	export function create(factory: State.Factory, client: Client): Listenable {
		const me = factory.create<History>(
			"object",
			{},
			{
				reports: Reports.create(factory, client),
				create: async report => {
					const result = !factory.state?.user.organizations.current
						? false
						: factory.state.errors.handle(
								await client.salaries.create(factory.state?.user.organizations.current.id, {
									...report,
									type: "salary",
								})
						  )
					if (result)
						me.reports[result.email] = [result, ...(me.reports?.[result.email] ?? [])]
					return result
				},
				remove: async (user, date) => {
					const result = !factory.state?.user.organizations.current
						? false
						: factory.state.errors.handle(
								await client.salaries.remove(factory.state.user.organizations.current.id, user, date)
						  )
					if (result)
						me.reports[user] = me.reports[user]?.filter(report => report.dates.end !== result.dates.end)
					return result
				},
			}
		)
		return me
	}
}
