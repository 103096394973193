import { Async } from "../../Async"
import { List as StatelyList } from "../../List/index"
import { Listenable } from "../../Listenable"
import type { Factory } from "../index"
import { navigation } from "../navigation"
import { Handler as Base } from "./Base"

export class List<T> extends Base<StatelyList<T>> {
	private backend: () => T[] | undefined
	private configuration: List.Configuration<StatelyList.Configuration<any>>
	constructor(factory: Factory, configuration: StatelyList.Configuration<T>, backend: T[] | undefined) {
		const processed = List.processConfiguration(configuration)
		const { result, ...internals } = StatelyList.create(processed, backend, factory)
		super(factory, result)
		this.backend = internals.backend
		this.configuration = processed as any
	}
	start(target?: Listenable<any>): void {
		const backend = this.backend() ?? []
		for (const path of this.configuration?.invalidate ?? [])
			if (typeof path == "string") {
				const dependency = navigation.resolve(this.factory, backend, path)
				if (dependency && (!target || target === dependency.target))
					this.state.listen(
						"read",
						() => dependency.target.listen(dependency.key, () => this.state.invalidate(), { passive: true }),
						{ once: true, passive: true }
					)
			}
		for (const path of this.configuration.reload ?? [])
			if (typeof path == "string") {
				const dependency = navigation.resolve(this.factory, backend, path)
				if (dependency && (!target || target === dependency.target))
					this.state.listen(
						"read",
						() => dependency.target.listen(dependency.key, () => this.reload(), { passive: true }),
						{ once: true, passive: true }
					)
			}
	}

	reload(): void {
		const current = this.backend()
		const configuration = this.configuration
		if (configuration) {
			if (current === undefined)
				this.state.change(configuration.initiate?.({ state: this.factory.state, me: this.state, current }))
			configuration
				.load?.({ state: this.factory.state, me: this.state, current })
				.then(result => this.state.change(result))
		}
	}
	private static processConfiguration<T>(
		configuration: StatelyList.Configuration<T>
	): List.Configuration<StatelyList.Configuration<T>> {
		return {
			...(({ next, load, ...configuration }) => configuration)(configuration),
			...(configuration.load && {
				load: Async.lazy<typeof configuration.load>(() =>
					Async.awaitLatest(async (...argument) => configuration.load?.(...argument))
				),
			}),
			...(configuration.next && {
				next: Async.lazy<typeof configuration.next>(() =>
					Async.awaitLatest(async (...argument) => configuration.next?.(...argument))
				),
			}),
		}
	}
}
export namespace List {
	export type Configuration<TConfiguration extends StatelyList.Configuration<any>> =
		TConfiguration extends StatelyList.Configuration<infer T>
			? {
					[Configuration in keyof StatelyList.Configuration<T>]?: Configuration extends "load" | "next"
						? Async.Lazy<Required<StatelyList.Configuration<T>>[Configuration]>
						: StatelyList.Configuration<T>[Configuration]
			  }
			: never
}
