import { isoly } from "isoly"
import "../../stately2"
import "./Test3"
import { userwidgets } from "@userwidgets/model"
import { State as UserwidgetsState } from "@userwidgets/ui"
import { weekmeter } from "@weekmeter/model"
import { stately } from "../../stately"
import { Client, client } from "../Client"
import { Activities as StateActivities } from "./Activities"
import { Clients as StateClients } from "./Clients"
import { Profiles as StateProfiles } from "./Profiles"
import { Projects as StateProjects } from "./Projects"
import { Rules as StateRules } from "./Rules"
import { Salaries as StateSalaries } from "./Salaries"
import { Times as StateTimes } from "./Times"
import { State as StateUserwidgets } from "./Userwidgets"
export interface State {
	today: isoly.Date
	readonly errors: stately.Errors
	readonly user: State.Userwidgets.Listenable
	readonly clients: State.Clients.Listenable
	readonly profiles: State.Profiles.Listenable
	readonly projects: State.Projects.Listenable
	readonly activities: State.Activities.Listenable
	readonly rules: State.Rules.Listenable
	readonly salaries: State.Salaries.Listenable
	readonly times: State.Times.Listenable
	readonly userwidgets: UserwidgetsState
}
export namespace State {
	export import Userwidgets = StateUserwidgets
	export import Activities = StateActivities
	export import Clients = StateClients
	export import Profiles = StateProfiles
	export import Projects = StateProjects
	export import Rules = StateRules
	export import Salaries = StateSalaries
	export import Times = StateTimes
	export type Listenable = stately.Object<State>
	export type Factory = stately.Factory<Listenable>
	export function create(client: Client): stately.Object<State> {
		const factory = stately.Factory.create<State.Listenable>()
		const result = factory.create<State>(
			"object",
			{},
			{
				today: isoly.Date.now(),
				errors: stately.Errors.create(),
				user: Userwidgets.create(client.user),
				clients: Clients.create(factory, client),
				profiles: Profiles.create(factory, client),
				projects: Projects.create(factory, client),
				activities: Activities.create(factory, client),
				rules: Rules.create(factory, client),
				salaries: Salaries.create(factory, client),
				times: Times.create(factory, client),
				get userwidgets(): UserwidgetsState {
					return this.user as any as UserwidgetsState
				},
			}
		)
		factory.start(result)
		timer(result)
		result.user.roles.value = Object.entries(weekmeter.Roles.record).map(([label, flags]) => ({
			label: fromCamelCase(label),
			permissions: id => flags.map(flag => `${id}.${flag}`).join(" "),
		}))
		return result
	}
	function capitalize(name: string): string {
		return (name.at(0)?.toLocaleUpperCase() ?? "") + name.slice(1)
	}
	const pattern = /(?<!^)([A-Z])/g
	function fromCamelCase(name: string): string {
		const [first, ...other] = name.replace(pattern, " $1").split(" ")
		return [capitalize(first ?? ""), ...other.map(other => other.toLocaleLowerCase())].join(" ")
	}
	async function timer(state: State) {
		while (
			await new Promise<true>(resolve =>
				window.setTimeout(
					() => resolve(true),
					isoly.DateTime.epoch(isoly.DateTime.startOfDay(isoly.Date.next(state.today)), "milliseconds") -
						isoly.DateTime.epoch(isoly.DateTime.now(), "milliseconds")
				)
			)
		)
			state.today = isoly.Date.now()
	}
}

export const state = State.create(client)
// state.user.me.onUnauthorized = stately.Async.lazy(() => async () => {
// 	// return !!(await state.user.me.login({ user: "jessie@rocket.com", password: "asd123" }))
// 	await state.user.me.login({ user: "james@rocket.com", password: "asd123" })
// 	return false
// })
Object.assign(globalThis, { state, weekmeter, isoly, userwidgets, appModel: weekmeter })
