import { stately } from "../../../../stately"
import type { State } from ".."
import { Double as ListDouble } from "./Double"
import { Numbers as ListNumbers } from "./Numbers"
export interface List {
	readonly sum?: number
	readonly numbers: List.Numbers.Listenable
	readonly double: List.Double.Listenable
}
export namespace List {
	export import Numbers = ListNumbers
	export import Double = ListDouble
	export type Listenable = stately.Object<List>
	export function create(factory: State.Factory): Listenable {
		const me = factory.create<List>(
			"object",
			{
				sum: {
					load: async ({ me }) => {
						console.log(`List sum loading...`)
						const numbers = me.numbers.toArray()
						const double = me.double.toArray()
						const result = {
							numbers: numbers.reduce((result, number) => result + number, 0),
							double: double.reduce((result, number) => result + number, 0),
							get sum(): number {
								return this.numbers + this.double
							},
						}
						console.log(
							`List sum loaded: ${numbers.join(" + ") + double.join(" + ") || 0} = ${result.numbers} + ${
								result.double
							} = ${result.sum}`
						)
						return result.sum
					},
					reload: ["list.numbers.change", "list.double.change"],
				},
			},
			{
				numbers: Numbers.create(factory),
				double: Double.create(factory),
			}
		)
		return me
	}
}
