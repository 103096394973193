import { stately } from "../../../../stately"
import type { State } from ".."

export interface Numbers {
	a?: number
	b?: number
	c?: number
}
export namespace Numbers {
	export type Listenable = stately.Object<Numbers>
	export function create(factory: State.Factory): Listenable {
		const me = factory.create<Numbers>("object", {}, {})
		return me
	}
}
