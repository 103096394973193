import { gracely } from "gracely"
import { userwidgets } from "@userwidgets/model"
import { weekmeter } from "@weekmeter/model"
import { http } from "cloudly-http"

export class Clients {
	constructor(private readonly client: http.Client<gracely.Error>) {}
	async create(client: weekmeter.Client.Creatable): Promise<weekmeter.Client | gracely.Error> {
		return await this.client.post(`/client`, client, { organization: client.organization })
	}
	async list(organization: userwidgets.Organization.Identifier): Promise<weekmeter.Client[] | gracely.Error> {
		return await this.client.get(`/client`, { organization })
	}
}
