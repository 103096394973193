import { gracely } from "gracely"
import { userwidgets } from "@userwidgets/model"
import { weekmeter } from "@weekmeter/model"
import { http } from "cloudly-http"

export class Rules {
	constructor(private readonly client: http.Client<gracely.Error>) {}
	async list(
		organization: userwidgets.Organization.Identifier,
		users?: userwidgets.Email[]
	): Promise<weekmeter.Time.Rules | gracely.Error> {
		const search = new URLSearchParams()
		users?.forEach(user => search.append("user", user))
		return this.client.get(`/rule?${search.toString()}`, { organization })
	}
	async update(
		organization: userwidgets.Organization.Identifier,
		rules: weekmeter.Time.Rules.Changeable
	): Promise<weekmeter.Time.Rules | gracely.Error> {
		return this.client.patch(`/rule`, rules, { organization })
	}
}
