import { isoly } from "isoly"
import { userwidgets } from "@userwidgets/model"
import { weekmeter } from "@weekmeter/model"
import { stately } from "../../../../../stately"
import { Client } from "../../../../Client"
import type { State } from "../../../index"
import type { Days } from "../index"
import { Activities as DayActivities } from "./Activities"

export interface Day {
	balance?: stately.Loadable<isoly.TimeSpan>
	delta?: stately.Loadable<isoly.TimeSpan>
	sum?: stately.Loadable<isoly.TimeSpan>
	expected?: stately.Loadable<isoly.TimeSpan>
	readonly activities: Day.Activities.Listenable
}
export namespace Day {
	export import Activities = DayActivities
	export type Listenable = stately.Object<Day>
	export function create(
		factory: State.Factory,
		days: Days.Listenable,
		user: userwidgets.Email,
		organization: userwidgets.Organization.Identifier,
		date: isoly.Date,
		day: weekmeter.Day,
		client: Client
	): Listenable {
		const activities = Activities.create(factory, user, organization, date, day, client)
		const controllers = new Map<keyof Day, stately.Listenable.Controller[]>()
		const previous = isoly.Date.previous(date)
		const me = factory.create<Day>(
			"object",
			{
				expected: {
					load: async ({ state }) => {
						const profile = !state?.user.me.key
							? false
							: state.profiles.record?.[state.user.me.key.email] ?? { email: state.user.me.key.email }
						const result = !profile
							? false
							: weekmeter.Time.Rule.expected(
									profile,
									[date],
									[...(state?.rules.common || []), ...(state?.rules.user?.[profile.email] || [])]
							  )
						return result
					},
					reload: ["rules.common", "rules.user", "profiles.record"],
				},
				sum: {
					load: async ({ me, property }) => {
						if (!controllers.has(property))
							controllers
								.set(property, [])
								.get(property)
								?.push(
									me.activities.listen("*", () => {
										return factory.reload(me, property)
									})
								)
						const result = isoly.TimeSpan.add(
							...stately.Record.values(me.activities).reduce<isoly.TimeSpan[]>(
								(result, entry) => result.concat(entry?.duration ?? []),
								[]
							)
						)
						return result
					},
				},
				delta: {
					load: async ({ me, property }) => {
						if (!controllers.has(property))
							controllers
								.set(property, [])
								.get(property)
								?.push(
									me.listen("expected", () => {
										return factory.reload(me, property)
									}),
									me.listen("sum", () => {
										return factory.reload(me, property)
									})
								)
						const result = isoly.TimeSpan.subtract(me.sum || {}, me.expected || {})
						return result
					},
				},
				balance: {
					load: async ({ state, me, property, current }) => {
						let result: stately.Loadable<isoly.TimeSpan>
						const latest = state?.salaries.history.reports[user]?.[0]
						if (!latest) {
							result = current
						} else {
							if (latest.dates.end < date) {
								if (!controllers.has(property)) {
									controllers
										.set(property, [])
										.get(property)
										?.push(
											days?.listen(previous, yesterday => {
												return yesterday?.listen(property, value => {
													return value && factory.reload(me, property)
												})
											}),
											me.listen("delta", value => {
												return value && factory.reload(me, property)
											})
										)
								}
								result = !days[previous]?.balance
									? undefined
									: isoly.TimeSpan.add(days[previous]?.balance || {}, me.delta || {})
							} else {
								result =
									!state.user.organizations.current || !state.user.me.key
										? false
										: (
												state.errors.handle(
													await client.balances.fetch(state.user.organizations.current.id, state.user.me.key.email, {
														date,
													})
												) || undefined
										  )?.value || false
							}
						}
						return result
					},
					reload: ["user.organizations.current", `salaries.history.reports.${user.replaceAll(/\./g, "\\.")}`],
				},
			},
			{
				activities: activities,
			}
		)
		return me
	}
}
