import { userwidgets } from "@userwidgets/model"
import { stately } from "../../../stately"
import type { State } from "./index"

export interface Users {
	current?: stately.Loadable<userwidgets.User>
	value?: stately.Loadable<userwidgets.User[]>
	invited?: stately.Loadable<userwidgets.User[]>
	update(
		email: userwidgets.Email,
		user: userwidgets.User.Changeable,
		options?: { entityTag?: string }
	): Promise<userwidgets.User | false>
	remove2fa(email: userwidgets.Email): Promise<userwidgets.User | false>
}
export namespace Users {
	const storage = "userwidgets.user.current"
	export function create(factory: State.Factory, client: userwidgets.ClientCollection): stately.Object<Users> {
		const state = factory.create<Users>(
			"object",
			{
				current: {
					load: async ({ me }) => {
						const email = window.localStorage.getItem(storage)
						const value = me.value || []
						return (email && value.find(user => user.email == email)) || value.at(0)
					},
					reload: ["users.value"],
					store: async ({ value }) => {
						if (!value)
							window.localStorage.removeItem(storage)
						else
							window.localStorage.setItem(storage, value.email)
						return value
					},
				},
				invited: {
					load: async ({ me, state }) => {
						const organization = state?.organizations.current || undefined
						const value = me.value || []
						return (
							state?.organizations.current &&
							me.value &&
							value.filter(user => !organization?.users.some(email => email == user.email))
						)
					},
					reload: ["organizations.current", "users.value"],
				},
				value: {
					load: async ({ state }) => {
						return !state ? false : state.errors.handle(await client.user.list())
					},
					reload: ["me.key"],
				},
			},
			{
				update: async (email, user, options) => {
					const result = !factory.state
						? false
						: factory.state.errors.handle(await client.user.update(email, user, options))
					if (result)
						factory.reload(state, "value")
					return result
				},
				remove2fa: async email => {
					const result = !factory.state ? false : factory.state.errors.handle(await client.user.remove2fa(email))
					if (result)
						factory.reload(state, "value")
					return result
				},
			}
		)
		return state
	}
}
